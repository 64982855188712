import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import PortableText from "./portableText";
import AuthorList from "./author-list";
import styled from 'styled-components';

import Space from './Space';
import Hero from './hero';
import OpeningHours from './OpeningHours';
import Location from './Location';

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt, site } = props;
  return (
    <Wrapper>
      {mainImage && (
        <Section type={'slim'} layout={'column'}>
          <Hero
            disabled={false}
            split={false}
            minHeight={'350px'}
            heading={mainImage?.alt}
            category={categories[0]?.title}
            image={mainImage}
          />
          <Space />
        </Section>
      )}
      
      <Section type={'slim'} layout={'row'} style={{justifyContent: 'space-between'}}>
        <Main>
          <Article>
            <Content>
              <h1>{title}</h1>
              {_rawBody && <PortableText blocks={_rawBody} />}

              <Space />

              <div style={{display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'space-between'}}>
              
              {authors && <AuthorList items={authors} title="Author" />}
              {publishedAt && (
                <p>
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), "MMMM Do, YYYY")}
                </p>
              )}
              </div>
            </Content>
          </Article>
        </Main>
        <Aside>
          <OpeningHours site={site} />

          <Space />
      
          <Location site={site} />
        </Aside>
      </Section>
      
      <Space />
    </Wrapper>
  );
}

export default BlogPost;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: self-start;
  width: 100%;
  height: 100%;
  background: #f0f4f5;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => props.type === 'slim' ? '90%' : '100%'};
  max-width: ${props => props.type === 'slim' ? '1420px' : '100%'};

  @media (min-width: 1024px) {
    flex-direction: ${props => props.layout === 'row' ? 'row' : 'column'};
  }

  h1 {
    font-size: 32px;
    margin: 0 0 16px 0;
    font-weight: 900;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 38px;
    }
  }

  h2 {
    font-size: 26px;
    margin: 16px 0;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 22px;
    margin: 16px 0;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 26px;
    }
  }

  h4 {
    font-size: 18px;
    margin: 16px 0;
    line-height: 1.3;

    @media (min-width: 1024px) {
      font-size: 22px;
    }
  }

  h5 {
    font-size: 18px;
    margin: 16px 0;
  }

  h6 {
    font-size: 16px;
    margin: 16px 0;
  }

  p {
    font-size: 18px;
    margin: 8px 0;
  }
`;

const Article = styled.article`
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 10px;
  width: 100%;
  transition: all 0.2s ease 0s;

  &:hover {
    box-shadow: 0px 10px 32px -10px rgba(0,0,0,0.1);
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1024px) {
    width: 30%;
    margin-left: 32px;
    margin-top: 0;
    position: sticky;
    top: 120px;
  }

  .CtaBlock {
    .CtaContent {
      max-width: 100%;

      h2 {
        font-size: 20px;

        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }

      .IconWrapper {
        padding: 0;

        .Icon {
          padding: 0;
          margin: -10px 0 4px -3px;
        }
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media (min-width: 1024px) {
    padding: 32px;
  }

  img {
    display: flex;
    width: 100%;
    border-radius: 10px;
  }
`;